import { Box } from '@chakra-ui/core';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import AddAddress from './addAddress';
import ChooseAddress from './chooseAddress';
import ConfirmEnrollment from './confirmEnrollment';
import ConfirmUser from './confirmUser';
import Home from './home';
import Oob from './oob';
import { initApp } from './slice';
import store from './store';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleMessage = (e) => {
      try {
        const action = JSON.parse(e.data);
        store.dispatch(action);
      } catch (e) {}
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener(handleMessage);
    };
  }, []);

  useEffect(() => {
    const initSdk = async () => {
      const params = qs.parse(location.search);
      if (!isEmpty(params)) {
        await window.srcsdk.setState(params);
        await window.srcsdk.initDcfSdk({ srcInitiatorId: 'deferredDcf' });
        await dispatch(
          initApp({
            card: params.card ? JSON.parse(params.card || '{}') : null,
            srcProfile: params.srcProfile
              ? JSON.parse(params.srcProfile || '{}')
              : null,
            srcCorrelationId: params.srcCorrelationId || null,
            isRecognized: params.isRecognized === 'true' ? true : false,
            checkoutResponseUri: params.checkoutResponseUri || null,
            idToken: params.idToken || null,
          })
        );
      }
    };
    initSdk();
  }, [dispatch, location.search]);

  return (
    <Box mx={1}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/confirm-user">
          <ConfirmUser />
        </Route>
        <Route path="/add-address">
          <AddAddress />
        </Route>
        <Route path="/choose-address">
          <ChooseAddress />
        </Route>
        <Route path="/oob">
          <Oob />
        </Route>
        <Route path="/confirm-enrollment">
          <ConfirmEnrollment />
        </Route>
      </Switch>
    </Box>
  );
};

export default App;
