import { Alert, AlertIcon, Box, Button } from '@chakra-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Checkbox from '../../common/components/Checkbox';
import TextField from '../../common/components/TextField';
import {
  composeValidators,
  mustBeValidEmail,
  required,
} from '../../common/utils/formValidators';
import { addConsumerIdentifiers, getAddConsumerIdentifiers } from '../../slice';

const ConsumerForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, success, error } = useSelector(getAddConsumerIdentifiers);

  const onSubmit = useCallback(
    (values) => {
      dispatch(addConsumerIdentifiers(values));
    },
    [dispatch]
  );

  useEffect(() => {
    if (success) {
      history.push('/confirm-user');
    }
    return () => {};
  }, [success, history]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Box width={['100%', '100%', '100%', '50%']}>
            {error && (
              <Alert status="error" mb={8}>
                <AlertIcon />
                {error}
              </Alert>
            )}
            <Field
              name="firstName"
              label="First name"
              component={TextField}
              validate={composeValidators(required)}
            />
            <Field
              name="lastName"
              label="Last name"
              component={TextField}
              validate={required}
            />
            <Field
              name="email"
              type="email"
              label="Email"
              component={TextField}
              validate={composeValidators(required, mustBeValidEmail)}
            />
            <Box mt={8}>
              <Field
                type="checkbox"
                name="requestRememberMeCookie"
                label="Remember me on this device for faster checkouts"
                component={Checkbox}
              />
              {values.requestRememberMeCookie && (
                <Box mt={2}>
                  <Field
                    name="applicationName"
                    label="Define friendly name for device to be bound:"
                    component={TextField}
                  />
                </Box>
              )}
            </Box>
            <Box mt={8}>
              <Button
                size="lg"
                type="submit"
                variantColor="purple"
                isLoading={isLoading}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default ConsumerForm;
