import { Divider, Flex, Icon, Image } from '@chakra-ui/core';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { getCheckoutResponseUri } from '../../slice';
import qs from 'query-string';

const Header = () => {
  const checkoutResponseUri = useSelector(getCheckoutResponseUri);

  return (
    <Fragment>
      <Flex alignItems="center">
        <Image src="/images/logo.png" width={8} mr="auto" />
        <Image src="/images/paymentIcons.png" width="112px" />
        <Icon
          ml="auto"
          name="close"
          size={4}
          cursor="pointer"
          onClick={() => {
            if (window.self === window.top && checkoutResponseUri) {
              window.location.href = `${checkoutResponseUri}?${qs.stringify({
                action: 'ERROR',
                errorCode: 'STANDALONE_DCF_CLOSED',
                errorDescription: 'The DCF run in standalone mode was closed.',
              })}`;
            } else {
              window.parent.postMessage(
                JSON.stringify({ type: 'dcf/cancel' }),
                '*'
              );
            }
          }}
        />
      </Flex>
      <Divider my={4} />
    </Fragment>
  );
};

export default Header;
