import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/core';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loader from '../common/components/Loader';
import { getCard, getError, getIsLoading, getSrcProfile } from '../slice';
import ConsumerForm from './components/ConsumerForm';

const Home = () => {
  const isLoading = useSelector(getIsLoading);
  const srcProfile = useSelector(getSrcProfile);
  const card = useSelector(getCard);
  const error = useSelector(getError);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box width={['100%', '100%', '100%', '50%']} my={8}>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }
  if (srcProfile) {
    return <Redirect to="/choose-address" />;
  }

  return (
    <Fragment>
      <Heading fontSize="md" my={8}>
        Add your info
      </Heading>
      <Flex alignItems="center" mb={8}>
        <Box mr={4}>
          <Image
            src={
              process.env.REACT_APP_CARD_PLACEHOLDER ||
              card.digitalCardData.artUri
            }
            w={24}
          />
        </Box>
        <Box>
          <Text>Discover</Text>
          <Text>**** {card.panLastFour}</Text>
        </Box>
      </Flex>
      <ConsumerForm />
    </Fragment>
  );
};

export default Home;
